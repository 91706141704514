.main {
  width: 100vw;
  height: 100vh;
  background-image: url("background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.box {
  width: min(350px, 100%);
  height: auto;
  text-align: center;
  background-color: #c7c6c3;
  margin-left: 10%;
  margin-top: 10%;
  padding: 10px 20px 20px 10px;
}
.box input {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
}
.box span {
  display: none;
  color: #f03838;
  margin: 20px;
  font-weight: 700;
}

.box button {
  margin-top: 20px;
  width: 100%;
  height: 50px;
  color: #fff;
  background-color: #8b1a25;
  border: 2px solid #8b1a25;
}
.box button:hover {
  cursor: pointer;
}
