h1 {
  color: #000;
}
.navigation {
  width: 100%;
  margin-bottom: 20px;
  margin: 0px;
  height: 300px;
  display: flex;
  background-image: url("./background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: space-around;
  align-items: center;
  color: #fff;

  z-index: 1330;
}
.layerDetail {
  padding: 20px;
  background-color: rgba(182, 181, 170, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 30px;
}

.layerDetail svg {
  position: absolute;
}

.layerDetail h1 {
  width: 100%;
  font-size: calc(2rem + 2vw);
}

.layerDetail input {
  width: min(200px, 90%);
  height: 30%;
  box-sizing: border-box;
  font-size: 2rem;
  margin: auto;
}

.detailsData {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.carImage {
  width: 70%;
  max-width: 100%;
  height: auto;
  margin: auto;
  text-align: center;
}

.userInfo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.userInfo h2 {
  width: 100%;
}
.userInfo img {
  width: 300px;
}
.detailsAboutImage {
  width: 100%;
  text-align: center;
}

.detailsAboutImage img {
  border-radius: 50%;
}

@media only screen and (max-width: 900px) {
  .detailsData img:nth-child(1) {
    width: 100%;
  }
  .carImage {
    width: 100%;
  }
  .detailsAboutImage {
    width: 100%;
  }

  .detailsAboutImage img {
    width: 30%;
    height: 100%;
    border-radius: 50%;
    text-align: center;
  }
}
