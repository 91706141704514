@import url("https://fonts.googleapis.com/css2?family=Barlow&display=swap");

h1 {
  margin-top: 0px;
  font-size: 3rem;
  text-align: center;
}

.list {
  width: 90vw;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  margin: auto;
  margin-top: 30px;
  font-family: "Barlow", sans-serif;
}

.container {
  border: 1px solid gray;
  text-align: center;
  padding: 20px;
}

.listContainer {
  overflow: hidden;
}

.listContainer button {
  position: absolute;
  margin: 20px;
  width: 60px;
  height: 40px;
  border-radius: 20%;
  background-color: #f03838;
  border: 2px solid #f03838;
  box-sizing: border-box;
  z-index: 2;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
}

.container h4 {
  color: red;
}

.container h5 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.listContainer img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center;
}
.listContainer img:hover {
  transition: 1s all ease-in-out;
  transform: scale(1.1);
}
.listContainer img:not(:hover) {
  transition: 1s all ease-in-out;
  transform: scale(1);
}

.loader {
  width: 100%;
  height: 300px;
  display: none;
  justify-content: center;
  align-items: center;
}
.lds-dual-ring {
  display: inline-block;
  width: 120px;
  height: 120px;
  margin: auto;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 100px;
  height: 100px;
  margin: 8px auto;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
#typeSth {
  display: none;
  margin-top: 20px;
}

.block {
  display: block !important;
}

.active {
  display: flex;
}

.notvisible {
  display: none;
}

#carError {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  color: #f03838;
  font-weight: 700;
  font-size: 2rem;
  display: none;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .list {
    width: 100vw;
    grid-template-columns: repeat(1, 1fr);
  }
}
